$(function () {

    // show hide subnav depending on scroll direction
    var position = $(window).scrollTop();

    var threshold = 70;

    var animationTimeout = null;
    var isRevealed = true;

    $(window).on('scroll', function () {
        if($(window).width() < 768) {
            var scroll = $(window).scrollTop();
            {
                if ( scroll > position && scroll > threshold  ) {
                    //down
                    if ( isRevealed ) {
                        isRevealed = false;
                        if ( animationTimeout ) {
                            clearTimeout(animationTimeout);
                        }
                        animationTimeout = setTimeout(function () {
                            $("header").fadeOut('slow');
                            $("header .menu").slideUp();
                            $("header .xs-menu-button").fadeOut();
                            animationTimeout = null;
                        }, 100);
                    }
                } else {
                    //up
                    if ( !isRevealed ) {
                        isRevealed = true;
                        if ( animationTimeout ) {
                            clearTimeout(animationTimeout);
                        }
                        animationTimeout = setTimeout(function () {
                            $("header").slideDown().fadeIn();
                            $("header .xs-menu-button").fadeIn();
                            animationTimeout = null;
                        }, 100);
                    }
                }
            }
        }
        position = scroll;
    });


    $("header .logo").on('click', function () {
        toggleMenu();
    });

    $("header .xs-menu-button").on('click', function () {
        toggleMenu();
    });

    $(window).on('resize', function() {
        if($(window).width() > 767) {
            $("header").show();
            $("header .menu").show();
            $("header .xs-menu-button").hide();
        }
        else {
            $("header").show();
            $("header .xs-menu-button").show();
        }
    });

    function toggleMenu()
    {
        if($(window).width() < 768)
        {
            $("header .menu").slideToggle();
        }
    }

});
