$(function(){

    var filesDir = '/img/home/illustrations/';
    var files = 34;
    var spacingX = 400;
    var spacingY = 250;
    var spacingJitter = 0.1;
    var margin = 150;
    var replaceTime = 3000;
    var speed = 10;
    var fps = 30;

    function Mosaic(element){
        this.element = element;
        this.preload();
    }

    Mosaic.prototype.preload = function(){
        this.imagesLoaded = 0;
        for ( var i=1; i<=files; i++ ) {
            var img = new Image();
            img.onload = this.onImageLoad.bind(this);
            img.src = filesDir + i + '.png';
        }
    };

    Mosaic.prototype.onImageLoad = function(){
        this.imagesLoaded++;
        if ( this.imagesLoaded == files ) {

            // start it up
            this.generateTiles();
            this.replaceInterval = setInterval(this.replaceRandomTile.bind(this), replaceTime);

            this.animation = new AnimationFrame(fps);
            this.animation.request(this.onAnimationFrame.bind(this));

        }
    };

    Mosaic.prototype.generateTiles = function(){
        this.tiles = [];

        var y = 60;
        var row = 0;
        while ( y < $(this.element).height() + 50 && row < 3 ) {

            var x = -margin + ( row%2 ? 0 : spacingX/2 );
            while ( x < $(this.element).width() + margin ) {
                var tile = new MosaicTile(this.element);
                tile.x = x;
                tile.y = y;
                tile.row = row;

                this.tiles.push(tile);

                x += spacingX;
            }

            row++;
            y += spacingY;
        }

        // randomly fade in tiles
        var tiles = this.tiles.slice();
        var delay = 0;
        while ( tiles.length ) {
            var tile = tiles.splice(Math.floor(Math.random()*tiles.length), 1)[0];
            tile.replace(this.selectRandomImageFile(), delay);
            delay += 200;
        }

    };

    Mosaic.prototype.replaceRandomTile = function(){
        var tile = this.tiles[Math.floor(Math.random()*this.tiles.length)];
        tile.replace(this.selectRandomImageFile());
    };

    Mosaic.prototype.selectRandomImageFile = function() {
        var file;
        do {
            file = Math.ceil(Math.random() * files);
        } while ( this.tiles.filter(function(tile){return tile.file==file;}).length );
        return file;
    };

    Mosaic.prototype.onAnimationFrame = function(time){
        var fileSelector = this.selectRandomImageFile.bind(this);
        this.tiles.forEach(function(tile){
           tile.animate(1/fps, fileSelector);
        });
        this.animation.request(this.onAnimationFrame.bind(this));
    };




    function MosaicTile(parent){
        this._x = 0;
        this._y = 0;
        this.xOffset = 0;
        this.yOffset = 0;
        this.img = null;
        this.parent = parent;
    }

    Object.defineProperty(MosaicTile.prototype, 'x', {
        get: function() { return this._x; },
        set: function(value) { 
            this._x = value;
            if ( this.img ) {
                this.img.css('left', this._x + this.xOffset);
            }
        }
    });

    Object.defineProperty(MosaicTile.prototype, 'y', {
        get: function() { return this._y; },
        set: function(value) { 
            this._y = value;
            if ( this.img ) {
                this.img.css('top', this._y + this.yOffset);
            }
        }
    });
    
    MosaicTile.prototype.replace = function(file, delay){
        if ( this.isReplacing ) return;

        this.isReplacing = true;
        this.file = file;

        delay = delay || 0;

        if ( this.img ) {
            this.img.fadeOut(1000, function(){ $(this).remove() });
            delay += 1000;
        }

        setTimeout(function(){
            var path = filesDir + file + '.png';
            this.img = $('<img>').attr('src', path).addClass('mosaic-tile');

            this.xOffset = spacingX * (spacingJitter*(Math.random()*2-1));
            this.yOffset = spacingY * (spacingJitter*(Math.random()*2-1));

            this.x = this.x; //refresh position
            this.y = this.y;

            this.img.fadeIn(1000);
            this.img.appendTo($(this.parent));

            this.isReplacing = false;
        }.bind(this), delay);
    };

    MosaicTile.prototype.animate = function(dt, fileSelector){
//        this.x += speed * dt * ( 1 + this.row/2);
        var dx = this.x - ( $(this.parent).width() + margin );
        if ( dx > 0 ) {
//            this.x = -margin + dx;
            this.replace(fileSelector());
        }
    };



    // INIT

    if ( $(window).width() >= 768 ) {
        $('.mosaic').each(function () {
            var mosaic = new Mosaic($(this));
        });
    }

});
