$(function () {
    var yOffset = 400;
    var xOffset = 500;
    var scrollBuffer = 150;

    function setPositionTop(img, position) {
        fastdom.write(function() {$(img).css("top", position+"px");});
    }
    function setPositionLeftRight(img, position, opacity) {
        if($(img).hasClass("left")) {fastdom.write(function() {$(img).css("left", position+"px");});}
        else if($(img).hasClass("right")) {fastdom.write(function() {$(img).css("right", position+"px");});}
        fastdom.write(function() {$(img).css("opacity",opacity);});
    }

//// on scroll

    $(window).on('scroll resize', function () {
        fastdom.read(function() {
            $.each($("img.easeUp"), function(index, img){
                if($(window).width() >= 768) {
                    var distanceToTop = $(img).offset().top - $(window).scrollTop();

                    if(distanceToTop<window.innerHeight+yOffset - scrollBuffer && distanceToTop>0) // on screen
                        setPositionTop(img, 0);
                    else if (distanceToTop>window.innerHeight - scrollBuffer) // below the screen
                        setPositionTop(img, yOffset);
                    else // above the screen
                        setPositionTop(img, 0);
                }
                else {
                    setPositionTop(img, 0);
                }
            });
        });
        fastdom.read(function() {
            $.each($("img.easeIn"), function(index, img){
                if($(window).width() >= 768) {
                    var distanceToTop = $(img).offset().top - $(window).scrollTop();

                    if(distanceToTop<window.innerHeight && distanceToTop>0)// on screen
                        setPositionLeftRight(img, 0,1);
                    else if (distanceToTop>window.innerHeight) // below the screen
                        setPositionLeftRight(img, -xOffset,0);
                    else  // above the screen
                        setPositionLeftRight(img, 0,1);
                }
                else {
                    setPositionLeftRight(img, 0,1);
                }
            });
        });
    });


////// on load

    $.each($("img.easeUp"), function(index, img){
        fastdom.read(function() {
            if($(window).width() >= 768) {setPositionTop(img, yOffset);}
            fastdom.write(function() {
              if(!$(img).hasClass("absolute")) {$(img).css("position","relative");}
              $(img).css("visibility", "visible");
            });
            fastdom.defer(function() {$(img).css('transition','top 1s');});
            var distanceToTop = $(img).offset().top - $(window).scrollTop();
            if(distanceToTop<window.innerHeight+yOffset - scrollBuffer) fastdom.defer(function() {setPositionTop(img, 0);});
        });
    });
    $.each($("img.easeIn"), function(index, img){
        fastdom.read(function() {
            if($(window).width() >= 768) {setPositionLeftRight(img, -xOffset/2, 0);}
            fastdom.write(function() {
              if(!$(img).hasClass("absolute")) {$(img).css("position","relative");}
              $(img).css("visibility", "visible");
            });
            fastdom.defer(function() {$(img).css('transition','left 1s ease 0.1s, right 1s ease 0.1s, opacity 1s ease 0.1s');});
            var distanceToTop = $(img).offset().top - $(window).scrollTop();
            if(distanceToTop<window.innerHeight - scrollBuffer) fastdom.defer(function() {setPositionLeftRight(img, 0,1);});
        });
    });

});
