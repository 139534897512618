$(function(){

    // Straight up mailchimp subscriptions are now being handled by standard non-ajax mailchimp forms, so this is only for the sample signup.

    $('#sample-form').on('submit', function(e){
        e.preventDefault();

        if ( !$(this).find('input[name=email]').val() ) {
            return;
        }

        $(this).find('.button').attr('disabled', 'disabled');

        var action = $(this).attr('action').replace('list-manage.com/subscribe/post', 'list-manage.com/subscribe/post-json');

        $.post(action, $(this).serialize(), 'jsonp')
            .always(function(){
                $(this).find('.success-response').text("Thanks! You should receive an email in a minute. If you use Gmail, check in your inbox's promotions tab.").show();
                $(this).find('.button').removeAttr('disabled');
                ga('send', 'event', 'mailchimp', 'success', 'Thanks! You should receive an email in a minute.');
            }.bind(this));
    });

});
