$(function(){

    if ( $('.convert-price-cad').length ) {
        $.getJSON("//api.fixer.io/latest", function (data) {
            fx.rates = data.rates;
            $('.convert-price-cad').each(function(){
                var usd = $(this).data('price') / 100;
                var cad = fx(usd).from("USD").to("CAD");
                $(this).text(cad.toFixed(2));
            });
        });
    }

});
