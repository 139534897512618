$(function() {
  var iframe = $('#video-modal iframe')[0];
  var player = $f(iframe);
  $(iframe).hide();
  
  $('.hero .video-button').on('click', function(){
    $(iframe).show();
    player.api('play');
  });
  $("#video-modal").bind('reveal:close',function(){
    player.api('pause');
    $(iframe).hide();
  });
  
});