$(function () {
    $('.faq-question').on('click', function(e){
        $q = $(this);
        $a = $q.next('.faq-answer');
        $q.toggleClass('selected', !$a.is(':visible'));
        $a.slideToggle('medium', function() {
            // do it again just in case somebody went crazy with the clicking
            $q.toggleClass('selected', $a.is(':visible'));
        });
    });
});